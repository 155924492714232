@font-face {
  font-family: "Graphik Medium";
  src: url("../assets/fonts/graphik-medium.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Graphik Regular";
  src: url("../assets/fonts/graphik-regular.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Graphik Light";
  src: url("../assets/fonts/graphik-light.ttf") format("truetype");
  font-style: normal;
}
