@import "../../../../styles/variables";

.imageSlider {
  grid-column: 3 / span 20;
  position: relative;

  @include grid(20);

  @media #{$grid-break} {
    flex-direction: column;
  }

  & .image {
    grid-column: 1 / span 9;
    width: 100%;
    object-fit: cover;

    @media #{$grid-break} {
      width: 100vw;
      height: 150vw;
    }

    @media #{$small} {
      width: 500px;
      height: 750px;
    }
  }

  & .credit {
    position: absolute;
    bottom: -30px;
    left: 0;

    @media #{$grid-break} {
      left: 60px;
    }
  }

  & div {
    &.arrow {
      width: 120px;

      @media #{$x-small} {
        width: 80px;
      }
    }
  }

  & .arrow {
    position: absolute;
    width: 70px;
    top: 0;
    height: 100%;
    z-index: 100;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.fake {
      height: 20px;
      top: calc(50% - 14px);
      pointer-events: none;

      @media #{$grid-break} {
        height: 18px;
        top: unset;
        bottom: 30px;
        width: auto;
      }

      &.left {
        left: -30px;

        @media #{$small} {
          left: -30px;
        }

        @media #{$x-small} {
          left: 30px;
        }
      }

      &.right {
        right: -30px;

        @media #{$small} {
          right: -30px;
        }

        @media #{$x-small} {
          right: 30px;
        }
      }
    }
  }

  & .darkOverlay {
    display: none;

    @media #{$grid-break} {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($secondary, 0.4);
      z-index: 1;
    }
  }

  & .headerContainer {
    grid-column: 13 / span 7;
    margin-top: 12%;
    z-index: 3;
    pointer-events: none;

    @media #{$grid-break} {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin-top: 0;
      padding-top: 12%;
      padding-left: 60px;
      padding-right: 30px;
    }

    & h4 {
      @media #{$grid-break} {
        max-width: 410px;
        color: $white;
      }
    }

    & .source {
      margin-top: 24px;
      pointer-events: all;

      & a {
        @media #{$grid-break} {
          color: $white;
        }
      }
    }
  }

  & .slideContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;

    & :global(.swiper-container) {
      width: 100%;
      height: 100%;
    }

    & .slideItem {
      width: 100%;
      height: 100%;

      @include grid(20);

      @media #{$grid-break} {
        flex-direction: column;
      }

      & .contentContainer {
        display: flex;
        flex-direction: column;
        grid-column: 13 / span 5;
        transform: translateY(45%);

        @media #{$grid-break} {
          padding-left: 60px;
          padding-right: 30px;
          padding-bottom: 114px;
          flex: 1;
          max-width: 400px;
        }

        @media #{$x-small} {
          padding-bottom: 84px;
        }

        & > h1 {
          @media #{$grid-break} {
            color: $white;
          }
        }

        & > p {
          @media #{$grid-break} {
            color: $white;
          }
        }
      }
    }
  }
}

/* stylelint-disable-next-line no-descending-specificity */
:global(.no-grid) {
  /* stylelint-disable-next-line no-descending-specificity */
  & :local(.imageSlider) {
    display: flex;
    flex-direction: column;
    max-width: 800px;

    & .arrow {
      &.fake {
        height: 18px;
        top: unset;
        bottom: 30px;
        width: auto;
      }
    }

    & .image {
      width: 600px;
      height: 900px;
    }

    & .credit {
      left: 60px;
    }

    & .darkOverlay {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($secondary, 0.4);
      z-index: 1;
    }

    & .headerContainer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin-top: 0;
      padding-top: 12%;
      padding-left: 60px;
      padding-right: 30px;

      & h4 {
        max-width: 410px;
        color: $white;
      }

      & .source {
        & a {
          color: $white;
        }
      }
    }

    & .slideContainer {
      top: 0;

      & .slideItem {
        display: flex;
        flex-direction: column;

        & .contentContainer {
          padding-left: 60px;
          padding-right: 30px;
          flex: 1;
          padding-bottom: 114px;

          & > h1 {
            color: $white;
          }

          & > p {
            color: $white;
          }
        }
      }
    }
  }
}
