@import "../../styles/variables";

.navigation {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0;
  margin-right: auto;
  margin-left: auto;

  @media #{$max} {
    padding: 0 10vw;
  }

  & > .items {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    align-items: center;
    justify-content: center;
  }

  & > .logoContainer {
    position: fixed;
    bottom: 2vw;
    right: 2vw;
    margin: 0;
    padding: 0;
  }
}
