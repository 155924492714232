@import "../../../../styles/variables";

.intro {
  grid-column: 8 / span 10;
  // margin-top: 0 !important;

  @media #{$grid-break} {
    display: block;
    padding-left: 60px;
    padding-right: 30px;
    max-width: 500px;
  }

  & > .pContainer {
    & > p {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

:global(.no-grid) {
  & :local(.intro) {
    display: block;
    padding-left: 60px;
    padding-right: 30px;
    max-width: 500px;
  }
}
