@import "../../../../styles/variables";

.video {
  grid-column-start: 4;
  grid-column-end: span 18;

  @include grid(18, block);

  & .title {
    grid-column-start: 10;
    grid-column-end: span 8;
    margin-bottom: 60px;

    @media #{$grid-break} {
      margin-bottom: 40px;
      padding-left: 60px;
      padding-right: 30px;
      max-width: 500px;
      box-sizing: border-box;
      margin-right: auto;
      margin-left: auto;
    }
  }

  & .videoWrapper {
    grid-column: 1 / span 18;
    position: relative;
    height: 0;
    padding-bottom: 56.25%;

    @media #{$grid-break} {
      display: flex;
      width: 100vw;
    }

    & iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  & .note {
    grid-column: 1 / span 11;
    margin-top: 30px;

    @media #{$grid-break} {
      padding-left: 60px;
      padding-right: 30px;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.small {
    grid-column-end: span 13;

    @include grid(13, block);

    & .videoWrapper {
      grid-column: 1 / span 13;
    }

    & .title {
      grid-column-end: span 7;
    }
  }

  &.right {
    grid-column-start: 9;

    & .title {
      grid-column-start: 2;
    }
  }

  &.left {
    grid-column-start: 4;

    & .title {
      grid-column-start: 7;
    }
  }
}

:global(.no-grid) {
  & :local(.video) {
    display: block;

    & .title {
      margin-bottom: 40px;
      padding-left: 60px;
      padding-right: 30px;
      max-width: 500px;
    }

    & .videoWrapper {
      display: flex;
      width: 800px;
      height: 450px;
      padding-bottom: 0;

      & iframe {
        width: 800px;
        height: 450px;
      }
    }

    & .note {
      padding-left: 0;
      max-width: 500px;
    }
  }
}
