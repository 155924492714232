@import "../../styles/variables";

.grid {
  padding-top: 70px;
  padding-bottom: 70px;
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;

  @media #{$grid-break} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  & > * {
    margin-top: 70px;
    margin-bottom: 70px;

    @media #{$grid-break} {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  &.inner {
    position: relative;
    width: auto;
    grid-column: 1 / span 24;
    padding: 70px 40px 70px 40px;
    background: $alternative-1;
    transform: translateX(-40px);

    @media #{$grid-break} {
      transform: translateX(0);
      padding: 0 0 40px 0;
    }

    & > * {
      &:first-child {
        @media #{$grid-break} {
          margin-top: 0;
        }
      }
    }
  }
}

:global(.no-grid) {
  & :local(.grid) {
    padding-top: 40px;
    padding-bottom: 40px;

    /* stylelint-disable-next-line */
    & > * {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}
