@import "../../../styles/variables";

.menuWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: $secondary;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.4s ease;
  z-index: -1;
  pointer-events: none;

  & .flexWrapper {
    grid-column: 10 / span 10;
  }

  & ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
  }

  & h1 {
    text-decoration: none;
    cursor: pointer;
    color: $white;
  }

  & h1:hover {
    color: $secondary;
    text-shadow:
      -1px -1px 0 #{$white},
      1px -1px 0 #{$white},
      -1px 1px 0 #{$white},
      1px 1px 0 $white;
  }

  & .closeMenuIcon {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 32px;
    right: 32px;
    border-radius: 100%;
  }

  & .logoContainer {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding-left: 30px;

    @media #{$grid-break} {
      bottom: 80px;
    }

    @media #{$iphone-11-or-XR} {
      bottom: 120px;
    }

    @media #{$iphone-11-pro-or-X-or-XS} {
      bottom: 120px;
    }

    @media #{$iphone-11-pro-max-or-XS-max} {
      bottom: 120px;
    }
  }

  &.visible {
    opacity: 1;
    z-index: 1;
    pointer-events: unset;
  }
}
