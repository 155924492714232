@import "../../../../styles/variables";

.fact {
  grid-column: 10 / span 8;

  @media #{$grid-break} {
    width: 100%;
    padding-left: 60px;
    padding-right: 30px;
    max-width: 500px;
  }

  @media #{$medium} {
    grid-column: 10 / span 10;
  }

  & > .pContainer {
    & > p {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  & > .source {
    margin-top: 24px;
  }
}

:global(.no-grid) {
  & :local(.fact) {
    width: 100%;
    padding-left: 60px;
    padding-right: 30px;
    max-width: 500px;
  }
}
