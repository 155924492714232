@import "../../../styles/variables";

.navigationItem {
  position: relative;
  width: 22.5vw;
  height: 15vw;
  margin: 50px 50px;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
  overflow: hidden;

  &.passive {
    opacity: 0.1;
    overflow: hidden;
  }

  &.portrait {
    width: 15vw;
    height: 22.5vw;
  }

  & > .content {
    width: 25vw;
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: calc(-25vw - 24px);
    opacity: 0;
    transition: opacity 0.4s ease-in-out;

    & > .title {
      position: relative;
      font-family: $font-light;
      font-size: 3.125vw;
      line-height: 3.4375vw;
      color: $secondary;
      width: 25vw;
    }

    & > p {
      color: $secondary;
      width: 25vw;
      margin-bottom: 10px;
      padding-left: 5px;
      padding-right: 0;
    }

    &.left {
      left: calc(-25vw - 24px);

      & > .title {
        text-align: right;
      }

      & > p {
        text-align: right;
        padding-left: 0;
        padding-right: 5px;
      }
    }
  }

  &.active {
    overflow: visible;

    & > .content {
      opacity: 1;
    }
  }
}
