@import "../../../../styles/variables";

.squareVideo {
  grid-column: 5 / span 16;

  @include grid(16);

  @media #{$grid-break} {
    flex-direction: column;
  }

  @media #{$medium} {
    grid-template-columns: repeat(18, $column-width);
    grid-column: 5 / span 18;
  }

  & .videoWrapper {
    position: relative;
    grid-column: 1 / span 9;
    width: 100%;

    &::before {
      content: " ";
      width: 1px;
      display: inline-block;
      padding-top: calc(100% - 4px);
    }

    & .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      & iframe {
        height: 100%;
        width: 100%;
      }

      & .thumbnail {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  & .paragraphWrapper {
    grid-column: 11 / span 6;
    position: relative;

    @media #{$grid-break} {
      padding-left: 60px;
      padding-right: 30px;
      margin-top: 40px;
      max-width: 500px;
    }

    @media #{$medium} {
      grid-column: 11 / span 8;
    }

    & > p {
      position: absolute;
      bottom: 0;

      @media #{$grid-break} {
        position: relative;
      }
    }
  }
}

:global(.no-grid) {
  & :local(.square-video) {
    display: flex;
    flex-direction: column;

    & .paragraphWrapper {
      padding-left: 60px;
      padding-right: 30px;
      margin-top: 40px;
      max-width: 500px;

      & > p {
        position: relative;
      }
    }
  }
}
