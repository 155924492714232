@import "../../styles/variables";

.storyContent {
  & :global(.scroll-animation-component) {
    opacity: 0;
    transform: translateY(30px);
    transition: transform 1s, opacity 0.6s;

    &.show {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
