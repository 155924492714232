@import "../../../../styles/variables";

.quote {
  position: relative;
  grid-column: 10 / span 8;

  @media #{$grid-break} {
    width: 100%;
    padding-left: 60px;
    padding-right: 30px;
    max-width: 500px;
  }

  @media #{$medium} {
    grid-column: 10 / span 10;
  }

  & .quotemarkOpen {
    width: 14px;
    height: 20px;
    position: absolute;
    top: -10px;
    left: -24px;

    @media #{$grid-break} {
      left: 36px;
    }
  }

  & .quoteText {
    position: relative;

    & .quotemarkContainer {
      position: absolute;
      bottom: 0;
      width: 24px;
      height: 22px;
      text-align: right;
      display: inline;

      & .quotemarkClose {
        width: 14px;
        height: 20px;
      }
    }
  }

  & .source {
    margin-top: 24px;
  }
}

:global(.no-grid) {
  & :local(.quote) {
    width: calc(100% - 60px);
    margin-left: 60px;
    padding-right: 30px;
    max-width: 500px;
  }
}
