@import "../../../../styles/variables";

.bigImage {
  position: relative;
  grid-column: 4 / span 18;
  line-height: 0;

  & .photo {
    width: 100%;
    height: auto;
  }

  & .credit {
    margin-top: 10px;

    @media #{$grid-break} {
      padding-left: 60px;
      padding-right: 30px;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  & .detailsContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($primary, 0.9);
    opacity: 0;
    pointer-events: none;
    transition: opacity linear 0.2s;
    align-items: flex-end;

    @include grid(18);

    @media #{$grid-break} {
      padding-left: 60px;
      padding-right: 30px;
    }

    @media #{$x-small} {
      align-items: center;
    }

    &:hover {
      pointer-events: auto;
      opacity: 1;
    }

    &.hasCredit {
      height: calc(100% - 28px);
    }

    & .detailsContent {
      grid-column: 2 / span 8;
      position: absolute;
      padding-bottom: 70px;
      height: 100%;

      @include grid(8, block);

      @media #{$grid-break} {
        max-width: 410px;
        position: relative;
      }

      @media #{$medium} {
        grid-column: 2 / span 10;
        grid-template-columns: repeat(10, $column-width);
      }

      @media (min-width: 1024px) and (max-width: 1280px) {
        padding-bottom: 40px;
      }

      @media #{$small} {
        padding-bottom: 60px;
      }

      @media #{$x-small} {
        padding-bottom: unset;
      }

      & .title {
        grid-column: 1 / span 8;
        color: $secondary;
        margin-bottom: 12px;

        @media #{$medium} {
          grid-column: 1 / span 10;
        }
      }

      & .pContainer {
        grid-column: 1 / span 6;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;

        @media #{$x-small} {
          justify-content: center;
        }

        @media #{$medium} {
          grid-column: 1 / span 8;
        }

        & > p {
          color: $secondary;
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  & .detailsIcon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100px;
    height: 100px;
    padding-top: 40px;
    padding-left: 40px;
    cursor: pointer;

    &.hasCredit {
      bottom: 30px;
    }

    @media #{$grid-break} {
      width: 64px;
      height: 64px;
      padding-top: 20px;
      padding-left: 20px;
    }

    & > img {
      width: 30px;
      height: 30px;
      pointer-events: none;

      @media #{$grid-break} {
        width: 24px;
        height: 24px;
      }
    }

    &:hover ~ .detailsContainer {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

:global(.no-grid) {
  & :local(.bigImage) {
    & .photo {
      max-width: 800px;
    }

    & .credit {
      padding-left: 0;
      max-width: 500px;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    & .detailsContainer {
      display: flex;
      padding-left: 60px;
      padding-right: 30px;

      & .detailsContent {
        display: block;
        max-width: 410px;
        position: relative;
      }
    }

    & .detailsIcon {
      width: 64px;
      height: 64px;
      padding-top: 20px;
      padding-left: 20px;

      & > img {
        width: 24px;
        height: 24px;
      }
    }
  }
}
