@import "../../../../styles/variables";

.text {
  grid-column: 10 / span 8;

  @include grid(8, block);

  @media #{$grid-break} {
    padding-left: 60px;
    padding-right: 30px;
    max-width: 500px;
  }

  @media #{$medium} {
    grid-template-columns: repeat(10, $column-width);
    grid-column: 10 / span 10;
  }

  & > .title {
    grid-column: 1 / span 8;
    margin-bottom: 40px;

    @media #{$medium} {
      grid-column: 1 / span 10;
    }
  }

  & > .pContainer {
    grid-column: 1 / span 6;

    @media #{$medium} {
      grid-column: 1 / span 8;
    }

    & > p {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  & > .source {
    grid-column: 1 / span 6;
    margin-top: 24px;

    @media #{$medium} {
      grid-column: 1 / span 8;
    }
  }
}

:global(.no-grid) {
  & :local(.text) {
    display: block;
    padding-left: 60px;
    padding-right: 30px;
    max-width: 500px;
  }
}
