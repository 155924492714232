@import "../../styles/variables";

.logos {
  position: relative;
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  & > .essity {
    max-height: 4vh;
    height: 44px;

    @media #{$grid-break} {
      height: 38px;
      max-height: unset;
    }
  }

  & > .fotografiska {
    max-height: 8vh;
    height: 84px;
    margin-left: 40px;

    @media #{$grid-break} {
      height: 70px;
      max-height: unset;
    }
  }

  & > a {
    display: flex;

    & img {
      height: 100%;
    }
  }
}
