@import "../../../../styles/variables";

.statsSlider {
  grid-column: 3 / span 20;
  position: relative;

  @include grid(20);

  @media #{$grid-break} {
    flex-direction: column;
    width: 100vw;
  }

  @media #{$small} {
    align-items: center;
    width: 500px;
  }

  & .headerContainer {
    grid-column: 5 / span 8;
    margin-bottom: 80px;

    @include grid(8);

    @media #{$grid-break} {
      flex-direction: column;
      padding-left: 60px;
      padding-right: 30px;
      margin-bottom: 40px;
      width: 100%;
    }

    @media #{$small} {
      width: 500px;
    }

    @media #{$medium} {
      grid-column: 5 / span 10;
      grid-template-columns: repeat(10, $column-width);
    }

    & .title {
      grid-column: 1 / span 8;

      @media #{$medium} {
        grid-column: 1 / span 10;
      }
    }

    & .copy {
      grid-column: 1 / span 6;
      margin-top: 30px;

      @media #{$medium} {
        grid-column: 1 / span 8;
      }
    }
  }

  & .legend {
    grid-column: 5 / span 14;
    display: flex;
    margin-top: 70px;

    &.mobile {
      display: none;
      padding-left: 60px;
      padding-right: 30px;
      flex-direction: column;
      margin-top: 0;
      margin-bottom: 40px;
      width: 100%;

      @media #{$grid-break} {
        display: flex;
      }

      @media #{$small} {
        width: 500px;
      }
    }

    @media #{$grid-break} {
      display: none;
    }

    & > * {
      min-height: 20px;
      display: flex;
      align-items: center;

      @media #{$grid-break} {
        display: block;
        width: 100%;
      }
    }

    & .legendLeft {
      display: flex;
      margin-right: 25px;

      @media #{$grid-break} {
        margin-right: 0;
      }

      & span {
        width: 14px;
        height: 14px;
        background-color: $alternative-1;
        margin-right: 18px;
        display: inline-block;
      }

      & p {
        display: inline-block;
      }
    }

    & .legendRight {
      display: flex;
      margin-left: 25px;

      @media #{$grid-break} {
        margin-left: 0;
        margin-top: 16px;
      }

      & .legendHolder {
        border: 1px solid $secondary;
        width: 14px;
        height: 14px;
        margin-right: 14px;
        position: relative;
        display: inline-block;

        & img {
          position: absolute;
          width: 20px;
          height: 20px;
          top: -3.5px;
          left: -3.5px;
        }
      }

      & p {
        display: inline-block;
      }
    }
  }

  & .slideContainer {
    grid-column: 1 / span 20;
    position: relative;
    width: 100%;

    & div {
      &.arrow {
        width: 120px;

        @media #{$x-small} {
          width: 80px;
        }
      }
    }

    & .arrow {
      position: absolute;
      width: 70px;
      top: 0;
      height: 100%;
      z-index: 100;
      cursor: pointer;

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
      }

      &.fake {
        height: 20px;
        top: calc(50% - 14px);
        pointer-events: none;

        @media #{$grid-break} {
          height: 18px;
          top: unset;
          bottom: 30px;
          width: auto;
        }

        &.left {
          left: -20px;

          @media #{$grid-break} {
            left: 30px;
          }
        }

        &.right {
          right: -20px;

          @media #{$grid-break} {
            right: 30px;
          }
        }
      }
    }

    & .swiperContainer {
      width: 100%;
      height: 100%;
    }

    & .slideItem {
      width: 100%;
      height: 100%;

      @include grid(20);

      @media #{$grid-break} {
        flex-direction: column;
      }

      @media #{$small} {
        align-items: center;
      }

      & .contentContainer {
        grid-column: 5 / span 15;

        @media #{$grid-break} {
          padding-left: 60px;
          padding-right: 30px;
          flex: 1;
          width: 100%;
        }

        @media #{$small} {
          width: 500px;
        }

        & .country {
          margin-bottom: 24px;
          display: flex;

          @media #{$grid-break} {
            flex-direction: column;
          }

          &:last-child {
            margin-bottom: 0;

            @media #{$grid-break} {
              margin-bottom: 114px;
            }
          }

          & .countryLeftContainer {
            position: relative;

            & .countryName {
              display: inline;
              hyphens: none;
              white-space: nowrap;
            }

            & .leftPercentage {
              float: right;
              display: inline;

              @media #{$grid-break} {
                position: absolute;
                bottom: 1px;
                right: -30px;
              }
            }

            & .leftBlock {
              width: 100%;
              height: 10px;
              background-color: $alternative-1;
              display: inline-block;
            }
          }

          & .countryRightContainer {
            position: relative;
            margin-left: 3px;

            @media #{$grid-break} {
              margin-top: 5px;
              margin-left: 0;
            }

            & .countryName {
              display: inline-block;
              hyphens: none;
              white-space: nowrap;
              opacity: 0;
              width: 10px;

              @media #{$grid-break} {
                display: none;
              }
            }

            & .rightPercentage {
              float: right;
              display: inline;

              @media #{$grid-break} {
                position: absolute;
                bottom: 1px;
                right: -30px;
              }
            }

            & .rightBlock {
              width: 100%;
              height: 10px;
              display: inline-block;
              position: relative;

              & .scribbleBox {
                position: absolute;
                width: calc(100% - 2px);
                height: 8px;
                border: 1px solid $secondary;
                z-index: 1;
              }

              & .scribble {
                position: absolute;
                width: 100%;
                height: 19px;
                top: -4.5px;
                z-index: 2;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}

:global(.no-grid) {
  & :local(.stats-slider) {
    display: flex;
    flex-direction: column;
    width: 500px;
    align-items: center;

    & .headerContainer {
      display: flex;
      flex-direction: column;
      padding-left: 60px;
      padding-right: 30px;
      margin-bottom: 40px;
      width: 100%;
    }

    & .legend {
      display: none;

      &.mobile {
        display: flex;
      }

      & > * {
        display: block;
        width: 100%;
      }

      & .legendLeft {
        margin-right: 0;
      }

      & .legendRight {
        margin-left: 0;
        margin-top: 16px;
      }
    }

    & .slideContainer {
      & .arrow {
        &.fake {
          height: 18px;
          top: unset;
          bottom: 30px;
          width: auto;

          &.left {
            left: 30px;
          }

          &.right {
            right: 30px;
          }
        }
      }

      & .slideItem {
        display: flex;
        flex-direction: column;

        & .contentContainer {
          padding-left: 60px;
          padding-right: 30px;
          flex: 1;
          width: 100%;

          & .country {
            flex-direction: column;

            &:last-child {
              margin-bottom: 114px;
            }

            & .countryLeftContainer {
              & .leftPercentage {
                position: absolute;
                bottom: -2px;
                right: -40px;
              }
            }

            & .countryRightContainer {
              margin-top: 5px;
              margin-left: 0;

              & .countryName {
                display: none;
              }

              & .rightPercentage {
                position: absolute;
                bottom: -2px;
                right: -40px;
              }
            }
          }
        }
      }
    }
  }
}
