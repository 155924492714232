@import "../../styles/variables";

.privacyBanner {
  width: 100vw;
  padding: 30px;
  background: $alternative-1;
  z-index: 3;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;

  & p {
    margin-right: 16px;

    & > a {
      &:hover {
        color: rgba($secondary, 0.8);
      }
    }
  }

  &.visible {
    display: block;
  }

  & .innerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media #{$x-small} {
      justify-content: flex-start;
    }
  }

  & .acceptBtn {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}
