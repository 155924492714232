@import "../../styles/variables";

.transition {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background-color: $alternative-1;
  transform: translateY(100%);
  display: none;
  visibility: hidden;

  &.visible {
    display: block;
    visibility: visible;
    animation:
      part-1 0.5s 0s ease-in-out forwards,
      part-2 0.5s 0.7s ease-in-out forwards;
  }
}

@keyframes part-1 {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes part-2 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}
