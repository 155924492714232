@import "../../../../styles/variables";

.image {
  position: relative;

  & .credit {
    margin-top: 10px;

    @media #{$grid-break} {
      padding-left: 60px;
      padding-right: 30px;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  & .detailsContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($primary, 0.9);
    opacity: 0;
    pointer-events: none;
    transition: opacity linear 0.2s;
    align-items: flex-end;

    @include grid(13);

    @media #{$x-small} {
      align-items: center;
    }

    &:hover {
      pointer-events: auto;
      opacity: 1;
    }

    & .detailsContent {
      grid-column: 2 / span 8;
      padding-bottom: 70px;

      @include grid(8, block);

      &.hasCredit {
        margin-bottom: 30px;

        @media #{$grid-break} {
          margin-bottom: 0;
        }
      }

      @media #{$grid-break} {
        max-width: 410px;
        position: relative;
      }

      @media #{$medium} {
        grid-column: 2 / span 10;
        grid-template-columns: repeat(10, $column-width);
      }

      @media (min-width: 1024px) and (max-width: 1280px) {
        padding-bottom: 40px;
      }

      @media #{$small} {
        padding-bottom: 60px;
      }

      @media #{$x-small} {
        padding-bottom: unset;
        padding-left: 60px;
      }

      & .title {
        grid-column: 1 / span 8;
        color: $secondary;
        margin-bottom: 12px;

        @media #{$medium} {
          grid-column: 1 / span 10;
        }
      }

      & .pContainer {
        grid-column: 1 / span 6;

        @media #{$medium} {
          grid-column: 1 / span 8;
        }

        & > p {
          color: $secondary;
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.landscape {
    & .credit {
      @media #{$x-small} {
        padding-left: 30px;
      }

      @media #{$small} {
        padding-left: 45px;
      }
    }

    &.left {
      grid-column: 4 / span 13;

      @media #{$grid-break} {
        padding-right: 30px;
      }

      & .credit {
        @media #{$x-small} {
          padding-left: 60px;
        }

        @media #{$small} {
          padding-left: 75px;
        }
      }

      & .detailsContainer {
        & .detailsContent {
          @media #{$small} {
            padding-left: 60px;
          }

          @media #{$x-small} {
            padding-right: 60px;
          }
        }
      }
    }

    &.right {
      grid-column: 9 / span 13;

      @media #{$grid-break} {
        padding-left: 30px;
      }

      & .detailsIcon {
        @media #{$grid-break} {
          right: 0;
        }
      }

      & .detailsContainer {
        & .detailsContent {
          @media #{$small} {
            padding-left: 90px;
          }

          @media #{$x-small} {
            padding-right: 30px;
          }
        }
      }
    }
  }

  &.portrait {
    /* stylelint-disable-next-line no-descending-specificity */
    & .credit {
      @media #{$x-small} {
        padding-left: 30px;
      }
    }

    &.left {
      grid-column: 4 / span 9;
    }

    &.right {
      grid-column: 13 / span 9;
    }

    @media #{$grid-break} {
      padding-left: 30px;
      padding-right: 30px;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    & .detailsContainer {
      @include grid(9);

      /* stylelint-disable-next-line no-descending-specificity */
      & .detailsContent {
        grid-column: 2 / span 7;

        @include grid(7);

        @media #{$medium} {
          grid-column: 2 / span 7;
        }

        @media #{$small} {
          padding-left: 90px;
        }

        @media #{$x-small} {
          padding-right: 60px;
        }

        & .title {
          grid-column: 1 / span 7;

          @media #{$medium} {
            grid-column: 1 / span 7;
          }
        }

        & .pContainer {
          grid-column: 1 / span 6;

          @media #{$medium} {
            grid-column: 1 / span 6;
          }
        }
      }
    }
  }

  & > img {
    width: 100%;
    height: auto;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  & .detailsIcon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100px;
    height: 100px;
    padding-top: 40px;
    padding-left: 40px;
    cursor: pointer;

    &.hasCredit {
      bottom: 30px;
    }

    @media #{$grid-break} {
      right: 30px;
      width: 64px;
      height: 64px;
      padding-top: 20px;
      padding-left: 20px;
    }

    & > img {
      width: 30px;
      height: 30px;
      pointer-events: none;

      @media #{$grid-break} {
        width: 24px;
        height: 24px;
      }
    }

    &:hover ~ .detailsContainer {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

:global(.no-grid) {
  & :local(.image) {
    /* stylelint-disable-next-line no-descending-specificity */
    & .credit {
      padding-left: 0;
      max-width: 500px;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    & .detailsContainer {
      display: flex;

      /* stylelint-disable-next-line no-descending-specificity */
      & .detailsContent {
        display: block;
        max-width: 410px;
        position: relative;
      }
    }

    &.landscape {
      max-width: 800px;

      & > img {
        max-width: 800px;
      }

      &.left {
        padding-right: 0;
      }

      &.right {
        padding-left: 0;

        & .detailsIcon {
          right: 0;
        }
      }
    }

    &.right {
      padding-left: 0;
      padding-right: 0;

      & .detailsContainer {
        display: flex;
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    & .detailsIcon {
      right: 30px;
      width: 64px;
      height: 64px;
      padding-top: 20px;
      padding-left: 20px;

      & > img {
        width: 24px;
        height: 24px;
      }
    }

    &.portrait {
      max-width: 800px;
      padding-left: 0;
      padding-right: 0;

      & > img {
        max-width: 800px;
      }
    }
  }
}
