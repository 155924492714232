/* media queries */
$iphone-5: "(max-width: 320px)";
$x-small: "(max-width: 499px)";
$small: "(min-width: 500px) and (max-width: 1023px)";
$grid-break: "(max-width: 1023px)";
$medium: "(min-width: 1024px) and (max-width: 1440px)";
$regular: "(min-width: 500px) and (max-width: 1920px)";
$large: "(min-width: 1441px) and (max-width: 1920px)";
$max: "(min-width: 1921px)";

$iphone-11-or-XR: "only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)";
$iphone-11-pro-max-or-XS-max: "only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)";
$iphone-11-pro-or-X-or-XS: "only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)";

/* Typography */
$font-medium: "Graphik Medium", sans-serif;
$font-regular: "Graphik Regular", sans-serif;
$font-light: "Graphik Light", sans-serif;

/* Colors */
$white: #fff;
$secondary: #000;
$primary: #d8d6c8;
$alternative-1: #9aafb9;

/* Sizes */
$resize-ratio: calc((100vw - 500px) / (1920 - 500));

/* Grid */
$grid-width: calc(100vw - 80px);

$column-width: calc(#{$grid-width} * 0.0271);
$column-gap: calc(#{$grid-width} * 0.0152);

$max-column-width: 52.032px;
$max-column-gap: 29.184px;

@mixin grid($columns: 24, $display: flex) {
  display: grid;
  grid-template-columns: repeat($columns, $column-width);
  grid-column-gap: $column-gap;

  @media #{$grid-break} {
    display: $display;
  }

  @media #{$max} {
    grid-template-columns: repeat($columns, $max-column-width);
    grid-column-gap: $max-column-gap;
  }
}
