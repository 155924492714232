@import "../../../../styles/variables";

.share {
  grid-column: 10 / span 6;

  @media #{$grid-break} {
    padding-left: 60px;
    padding-right: 30px;
  }

  & .report {
    margin-bottom: 32px;

    @media #{$grid-break} {
      margin-bottom: 16px;
    }
  }

  & .itemWrapper {
    display: flex;
    align-items: center;

    @media #{$grid-break} {
      flex-direction: column;

      & > * {
        margin-right: auto;
      }
    }

    & .paragraph {
      margin-right: 20px;

      @media #{$grid-break} {
        margin-bottom: 12px;
        margin-right: auto;
      }
    }

    & .shareIcon {
      height: 36px;
      width: 36px;
    }

    & .shareItemWrapper {
      display: flex;

      & .item {
        margin-right: 20px;
        cursor: pointer;

        @media #{$grid-break} {
          margin-right: 20px;
        }
      }
    }
  }
}

:global(.no-grid) {
  & :local(.share) {
    padding-top: 40px;
    padding-bottom: 40px;

    & .report {
      margin-bottom: 16px;
    }

    & .itemWrapper {
      flex-direction: column;
    }

    & .paragraph {
      margin-bottom: 12px;
      margin-right: auto;
    }

    & .shareItemWrapper {
      & .item {
        margin-right: 20px;
      }
    }
  }
}
