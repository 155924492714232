@import "../../../styles/variables";

.menuIcon {
  width: 28px;
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  z-index: 1;
  height: 8px;
  transition: height 0.2s;

  @media #{$grid-break} {
    top: 30px;
    right: 30px;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 20px;
    top: 50%;
    margin-top: -10px;
    left: 0;
  }

  & .menuIconLine {
    height: 2px;
    background: $white;
    transition: transform 0.4s;
    transform: rotate(0);
    position: absolute;

    &:first-child {
      transform: rotate(0);
      transform-origin: 50% 50%;
      top: 0;
      width: 100%;
    }

    &:last-child {
      transform: rotate(0);
      transform-origin: 50% 50%;
      bottom: 0;
      width: 100%;
    }
  }

  &.black {
    & .menuIconLine {
      background: $secondary;
    }
  }

  &.visible {
    height: 2px;

    & .menuIconLine {
      background: $white;

      &:first-child {
        transform: rotate(-45deg);
      }

      &:last-child {
        transform: rotate(45deg);
      }
    }
  }
}
