@import "./variables";

html {
  color: $secondary;
  background: $primary;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;

  &.no-scroll {
    height: 100vh;
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span {
  font-weight: unset;
  margin: 0;
}

h3,
h4,
h5,
p,
a,
span {
  hyphens: auto;
}

b {
  font-family: $font-medium;
  font-weight: unset;
}

a {
  font-family: $font-medium;
  text-decoration: underline;
  color: $secondary;

  &:hover {
    color: $alternative-1;
  }
}

.medium {
  font-family: $font-medium;
  font-weight: unset;
}

.light {
  font-family: $font-light;
  font-weight: unset;
}

.regular {
  font-family: $font-regular;
  font-weight: unset;
}

/* Menu, Fact Title, Image Slider Title, TextWithHeadline Headline */
h1 {
  font-family: $font-light;
  font-size: 80px;
  line-height: 96px;
  letter-spacing: -0.2px;
  color: $secondary;

  @media #{$regular} {
    font-size: calc(40px + (80 - 40) * #{$resize-ratio});
    line-height: calc(48px + (96 - 48) * #{$resize-ratio});
  }

  @media #{$x-small} {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.1px;
  }

  @media #{$iphone-5} {
    font-size: 30px;
    line-height: 36px;
  }
}

/* Hero Title */
h2 {
  font-family: $font-light;
  font-size: 120px;
  line-height: 132px;
  color: $white;

  @media #{$regular} {
    font-size: calc(32px + (120 - 32) * #{$resize-ratio});
    line-height: calc(38.4px + (132 - 38.4) * #{$resize-ratio});
  }

  @media #{$x-small} {
    font-size: 32px;
    line-height: 38.4px;
    letter-spacing: -0.08px;
  }

  @media #{$iphone-5} {
    font-size: 24px;
    line-height: 28.8px;
  }
}

/* Fact Text, Quote Text */
h3 {
  font-family: $font-light;
  font-size: 28px;
  line-height: 39.2px;
  color: $secondary;

  @media #{$regular} {
    font-size: calc(24px + (28 - 24) * #{$resize-ratio});
    line-height: calc(31.2px + (39.2 - 31.2) * #{$resize-ratio});
  }

  @media #{$x-small} {
    font-size: 24px;
    line-height: 31.2px;
  }

  @media #{$iphone-5} {
    font-size: 18px;
    line-height: 21.6px;
  }
}

/* Text Title, Stats Title, Video Title, Share Report Text, Image Title, ImageSlider Title, BigImage Title */
h4 {
  font-family: $font-light;
  font-size: 36px;
  line-height: 43px;
  color: $secondary;
}

/* Fact, ImageSlider, Quote and Text source text */
h5 {
  font-family: $font-regular;
  font-size: 16px;
  line-height: 24px;
  color: $secondary;

  @media #{$regular} {
    font-size: calc(12px + (16 - 12) * #{$resize-ratio});
    line-height: calc(16.8px + (24 - 16.8) * #{$resize-ratio});
  }

  @media #{$x-small} {
    font-size: 12px;
    line-height: 16.8px;
  }

  @media #{$iphone-5} {
    font-size: 10px;
    line-height: 14px;
  }
}

/* Next Title */
h6 {
  font-family: $font-light;
  color: $white;
  font-size: 80px;
  line-height: 88px;
  letter-spacing: -0.2px;

  @media #{$grid-break} {
    font-size: 52px;
    line-height: 57.2px;
  }

  @media #{$medium} {
    font-size: 60px;
    line-height: 66px;
  }

  @media #{$large} {
    font-size: 80px;
    line-height: 88px;
  }

  @media #{$x-small} {
    font-size: 32px;
    line-height: 38.4px;
    letter-spacing: -0.08px;
  }

  @media #{$iphone-5} {
    font-size: 24px;
    line-height: 28.8px;
  }
}

p,
span {
  font-family: $font-regular;
  font-size: 16px;
  line-height: 24px;
  color: $secondary;

  @media #{$regular} {
    font-size: calc(14px + (16 - 14) * #{$resize-ratio});
    line-height: calc(22px + (24 - 22) * #{$resize-ratio});
  }

  @media #{$x-small} {
    font-size: 14px;
    line-height: 22px;
  }

  @media #{$iphone-5} {
    font-size: 12px;
    line-height: 18.8px;
  }
}

/* Navigation, Intro, Next, StatsSlider, TextWithHeadline, Video */
.tiny {
  font-size: 14px;
  line-height: 22px;

  @media #{$regular} {
    font-size: calc(12px + (14 - 12) * #{$resize-ratio});
    line-height: calc(18px + (22 - 18) * #{$resize-ratio});
  }

  @media #{$x-small} {
    font-size: 12px;
    line-height: 18px;
  }

  @media #{$iphone-5} {
    font-size: 10px;
    line-height: 14px;
  }
}

/* Next, Navigation (Mobile) */
.super-tiny {
  font-size: 12px;
  line-height: 14px;
  color: $white;
}

div {
  box-sizing: border-box;
}

.grid-container {
  width: 100vw;
  padding-left: 40px;
  padding-right: 40px;

  @include grid(24);

  @media #{$max} {
    padding-left: 0;
    padding-right: 0;
  }

  @media #{$grid-break} {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }

  @media #{$small} {
    align-items: center;
  }
}

.no-grid {
  & .grid-container {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    align-items: center;
  }
}
