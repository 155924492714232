@import "../../styles/variables";

.story {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: absolute;

  & .cover {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 40vw;
    min-height: 300px;
    transition: opacity 0.4s, height 0.4s;
    background-size: cover;
    opacity: 0;
    display: none;
    background-position: center;
    z-index: 3;

    &.visible {
      display: block;
    }

    &.expanded {
      height: 100%;
    }

    @media #{$small} {
      height: 62vw;
    }
  }
}
