@import "../../../../styles/variables";

.nextComponent {
  background-size: cover;
  background-position: center;
  height: 60vh;
  width: 100vw;
  position: relative;
  cursor: pointer;
  align-items: center;
  transition:
    transform 0.4s ease-in-out 0.4s,
    height 0.4s ease-in-out 0.4s;

  @media #{$grid-break} {
    justify-content: center;
    align-items: unset;
  }

  & .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($secondary, 0.3);
    opacity: 1;
    transition: opacity 0.4s;
  }

  & .content {
    position: relative;
    max-width: 1920px;
    height: 100%;
    grid-column: 10 / span 7;
    color: $white;
    margin-right: auto;
    margin-left: auto;
    padding-left: 40px;
    padding-right: 40px;
    opacity: 1;
    transition: opacity 0.4s;

    @include grid(24);

    @media #{$max} {
      padding-left: 0;
      padding-right: 0;
    }

    @media #{$grid-break} {
      width: 100%;
      padding-left: 60px;
      padding-right: 30px;
      max-width: 500px;
    }

    & .details {
      grid-column: 9 / span 12;
      display: flex;
      justify-content: center;
      flex-direction: column;

      & > .position {
        letter-spacing: 3px;
      }

      & > .title {
        width: 100%;
        margin: 30px 0;
        transform: scale(1);
        transition: 0.3s;

        @media #{$grid-break} {
          margin: 20px 0;
        }

        @media #{$medium} {
          max-width: 440px;
        }

        @media #{$large} {
          max-width: 600px;
        }

        @media #{$x-small} {
          max-width: 242px;
        }

        @media #{$iphone-5} {
          max-width: 180px;
        }
      }
    }
  }

  &.nextClicked {
    height: 75vh;

    & .content {
      opacity: 0;
    }

    & .overlay {
      opacity: 0;
    }
  }

  &:hover {
    & .content {
      & .details {
        & .title {
          transform: scale(1.025);
          transition: 0.3s;
        }
      }
    }
  }
}

:global(.no-grid) {
  & :local(.nextComponent) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    & .content {
      display: flex;
      width: 100%;
      padding-left: 60px;
      padding-right: 30px;
      max-width: 800px;

      & .details {
        & > .title {
          margin: 20px 0;
        }
      }
    }
  }
}
