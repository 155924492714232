@import "../../styles/variables";

.header {
  position: fixed;
  width: 100%;
  height: 0;
  padding: 40px 40px 0 40px;
  z-index: 3;
  transition: height 0s;
  transition-delay: 0.4s;

  & .transitionContainer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: $alternative-1;
    transform: translateY(100%);
    display: none;
    visibility: hidden;

    &.logoClicked {
      display: block;
      visibility: visible;
      animation:
        part-1 0.4s 0.2s ease-in-out forwards,
        part-2 0.4s 1s ease-in-out forwards;
    }
  }

  @media #{$grid-break} {
    position: absolute;
    padding: 30px 30px 0 30px;
  }

  &.fixed {
    position: fixed;

    @media #{$grid-break} {
      position: fixed;
    }
  }

  &.visible {
    height: 100vh;
    transition-delay: 0s;
  }

  & > img {
    opacity: 1;
    cursor: pointer;
    transition: opacity 0.4s;
    transform: translateY(0);
    height: 170px;

    @media #{$grid-break} {
      height: 132px;
    }
  }
}
