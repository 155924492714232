@import "../../../../styles/variables";

.textWithHeadline {
  grid-column: 5 / span 16;

  @include grid(16, block);

  @media #{$grid-break} {
    padding-left: 60px;
    padding-right: 30px;
    max-width: 500px;
  }

  & > .pContainer {
    grid-column: 11 / span 6;

    @media #{$medium} {
      grid-column: 10 / span 6;
    }

    & > p {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  & > h1 {
    grid-column: 1 / span 9;

    @media #{$grid-break} {
      margin-bottom: 30px;
    }

    @media #{$medium} {
      grid-column: 1 / span 8;
    }
  }
}

:global(.no-grid) {
  & :local(.textWithHeadline) {
    display: block;
    padding-left: 60px;
    padding-right: 30px;
    max-width: 500px;

    & > h1 {
      margin-bottom: 30px;
    }
  }
}
