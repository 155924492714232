@import "../../../../styles/variables";

.heroComponent {
  padding: 210px 0 70px 0;
  background-size: cover;
  background-position: center;
  position: relative;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100vw;

  @media #{$grid-break} {
    padding: 0;
    height: calc(60vh - 80px);
  }

  & .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($secondary, 0.1);
    opacity: 1;
  }

  & .content {
    position: relative;
    max-width: 1920px;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 40px;
    padding-right: 40px;

    @include grid(24);

    @media #{$max} {
      padding-left: 0;
      padding-right: 0;
    }

    @media #{$grid-break} {
      flex-direction: column;
      padding-left: 60px;
      padding-bottom: 0;
      padding-right: 30px;
      padding-right: 0;
      justify-content: flex-end;
      width: 100%;
      max-width: 500px;
    }

    & .title {
      grid-column: 7 / span 12;
      display: flex;
      align-items: center;

      & > h2 {
        color: $secondary;
      }

      @media #{$grid-break} {
        max-width: 230px;
      }

      @media #{$small} {
        max-width: 352px;
      }

      @media #{$iphone-5} {
        max-width: 180px;
      }
    }
  }
}

:global(.no-grid) {
  & :local(.heroComponent) {
    & .content {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
      justify-content: center;
      align-items: center;

      & .title {
        max-width: none;

        & > h2 {
          max-width: 1200px;
        }
      }
    }
  }
}
